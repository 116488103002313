import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '@/store';
import { multiSearchElasticUsers } from '@/api/elastic/handlers';
import { debouncePromise } from '@/utils/tools/helpers';
import { TClientSearchUser, TSearchResponse } from '@/types';

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const clientSearchSlice = createSlice({
  name: 'client-search',
  initialState: {
    query: '',
    loading: false,
    size: 200,
    current: 1,
    results: [] as TClientSearchUser[],
    end: false,
    filters: {
      role: 'user',
      teams: [] as string[],
      ismessagesunread: undefined as 'false' | 'true' | undefined,
      coachassignment__hascoachesassigned: undefined as 'false' | 'true' | undefined,
      isnewuser: undefined as 'false' | 'true' | undefined,
      isbgsnewuser: undefined as 'false' | 'true' | undefined,
      hasactivesession: undefined as 'false' | 'true' | undefined,
    },
    teams: [] as { value: string; count: number }[],
  },
  reducers: {
    toggleTeam: (state, action: PayloadAction<string>) => {
      if (!state.filters.teams.includes(action.payload)) {
        state.filters.teams = [...state.filters.teams, action.payload];
      } else state.filters.teams = state.filters.teams.filter((x) => x !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clientSearchQuery.pending, (state, value) => {
      state.query = value.meta.arg.query;
      state.loading = true;
      state.current = 1;
    });
    builder.addCase(clientSearchQuery.fulfilled, (state, action) => {
      state.results = action.payload.results;
      state.end = action.payload.results.length < state.size;
      state.teams = action.payload.facets.coachassignment__team[0].data;
      state.loading = false;
    });
    builder.addCase(clientSearchRefresh.fulfilled, (state, action) => {
      state.results = action.payload.results;
      state.end = action.payload.results.length < state.size;
    });
    builder.addCase(clientSearchLoadMore.pending, (state) => {
      state.current = state.current + 1;
      state.loading = true;
    });
    builder.addCase(clientSearchLoadMore.fulfilled, (state, action) => {
      state.teams = action.payload.facets.coachassignment__team[0].data;
      state.results = [...state.results, ...action.payload.results];
      state.loading = false;
      state.end = action.payload.results.length < state.size;
    });
    builder.addCase(clientSearchToggleTeam.pending, (state, action) => {
      state.loading = true;
      state.current = 1;
      if (!state.filters.teams.includes(action.meta.arg)) {
        state.filters.teams = [...state.filters.teams, action.meta.arg];
      } else {
        state.filters.teams = state.filters.teams.filter((x) => x !== action.meta.arg);
      }
    });
    builder.addCase(clientSearchToggleTeam.fulfilled, (state, action) => {
      state.end = action.payload.results.length < state.size;
      state.results = action.payload.results;
      state.loading = false;
    });

    builder.addCase(clientSearchFilterReadStatus.pending, (state, action) => {
      state.loading = true;
      state.current = 1;
      const arg = action.meta.arg;
      if (arg === 'read') state.filters.ismessagesunread = 'false';
      if (arg === 'unread') state.filters.ismessagesunread = 'true';
      if (arg === 'all') delete state.filters['ismessagesunread'];
    });
    builder.addCase(clientSearchFilterReadStatus.fulfilled, (state, action) => {
      state.end = action.payload.results.length < state.size;
      state.results = action.payload.results;
      state.loading = false;
    });

    builder.addCase(clientSearchFilterCoachNotAssigned.pending, (state, action) => {
      state.loading = true;
      state.current = 1;
      if (action.meta.arg) state.filters.isnewuser = 'true';
      else delete state.filters['isnewuser'];
    });
    builder.addCase(clientSearchFilterCoachNotAssigned.fulfilled, (state, action) => {
      state.end = action.payload.results.length < state.size;
      state.results = action.payload.results;
      state.loading = false;
    });

    builder
      .addCase(clientSearchFilterBgsClients.pending, (state, action) => {
        state.loading = true;
        state.current = 1;
        if (action.meta.arg) state.filters.isbgsnewuser = 'true';
        else delete state.filters['isbgsnewuser'];
      })
      .addCase(clientSearchFilterBgsClients.fulfilled, (state, action) => {
        state.end = action.payload.results.length < state.size;
        state.results = action.payload.results;
        state.loading = false;
      });
  },
});

export const { toggleTeam } = clientSearchSlice.actions;

// const debouncedSearch = debouncePromise(searchElasticUsers, 250);
const debouncedMultiSearch = debouncePromise(multiSearchElasticUsers, 250);
export const clientSearchQuery = createAppAsyncThunk<any, { query: string }>(
  'client-search/query',
  async (a, b) => {
    try {
      const state = b.getState().clientSearch;
      return debouncedMultiSearch(getMultiQuery({ ...state, query: a.query, current: 1 }));
    } catch (e) {
      console.log('error in search', e);
    }
  },
);

export const clientSearchRefresh = createAppAsyncThunk<any>(
  'client-search/refresh',
  async (_a, b) => {
    try {
      const state = b.getState().clientSearch;
      return multiSearchElasticUsers(getMultiQuery(state));
    } catch (e) {
      console.log('error in search refresh', e);
    }
  },
);

export const clientSearchLoadMore = createAppAsyncThunk<TSearchResponse>(
  'client-search/load-more',
  async (_a, b) => {
    const state: any = b.getState().clientSearch;
    return multiSearchElasticUsers(getMultiQuery({ ...state, current: state.current + 1 }));
  },
);

export const clientSearchToggleTeam = createAppAsyncThunk<TSearchResponse, string>(
  'client-search/toggle-team',
  async (_a, b) => {
    const state = b.getState().clientSearch;
    return multiSearchElasticUsers(getMultiQuery({ ...state, current: 1 }));
  },
);

export const clientSearchFilterReadStatus = createAppAsyncThunk<
  TSearchResponse,
  'read' | 'unread' | 'all'
>('client-search/filter-read-status', async (_a, b) => {
  const state = b.getState().clientSearch;
  return multiSearchElasticUsers(getMultiQuery({ ...state, current: 1 }));
});

export const clientSearchFilterCoachNotAssigned = createAppAsyncThunk<
  TSearchResponse,
  true | false
>('client-search/filter-coach-not-assigned', async (_a, b) => {
  const state = b.getState().clientSearch;
  return multiSearchElasticUsers(getMultiQuery({ ...state, current: 1 }));
});

export const clientSearchFilterBgsClients = createAppAsyncThunk<TSearchResponse, true | false>(
  'client-search/filter-bgs-clients',
  async (_a, b) => {
    const state = b.getState().clientSearch;
    return multiSearchElasticUsers(getMultiQuery({ ...state, current: 1 }));
  },
);

const getMultiQuery = (state: RootState['clientSearch']) => {
  return {
    queries: [
      {
        query: state.query,
        page: { size: state.size, current: state.current },
        search_fields: {
          firstname: { weight: 10 },
          lastname: { weight: 8 },
          email: { weight: 7 },
        },
        sort: [
          { ismessagesunread: 'desc' },
          { hasactivesession: 'desc' },
          { lastmessage__answerrequired: 'asc' },
          { lastmessage__createdat: 'desc' },
          { lastmessage__iscustomermsg: 'desc' },
        ],
        facets: {
          coachassignment__team: [{ type: 'value', sort: { count: 'asc' } }],
        },
        filters: {
          all: [
            ...(state.filters.role ? [{ role: state.filters.role }] : []),
            { hasactivesession: ['true'] },
            { ismessagesunread: ['true'] },
            { isonboarding: ['true'] },
          ],
          none: [
            { billingdata__status: ['cancelled'] },
            { hasactivesession: ['false'] },
            { iseow: ['true'] },
            { lastmessage_isengagementmessage: ['true'] },
          ],
          any: [
            ...state.filters.teams.map((t) => ({ coachassignment__team: t })),
            ...(state.filters.isnewuser ? [{ isnewuser: state.filters.isnewuser }] : []),
            ...(state.filters.isbgsnewuser ? [{ isbgsnewuser: state.filters.isbgsnewuser }] : []),
          ],
        },
      },
      {
        query: state.query,
        page: { size: state.size, current: state.current },
        search_fields: {
          firstname: { weight: 10 },
          lastname: { weight: 8 },
          email: { weight: 7 },
        },
        sort: [
          { ismessagesunread: 'desc' },
          { hasactivesession: 'desc' },
          { lastmessage__answerrequired: 'asc' },
          { lastmessage__createdat: 'desc' },
          { lastmessage__iscustomermsg: 'desc' },
        ],
        facets: {
          coachassignment__team: [{ type: 'value', sort: { count: 'asc' } }],
        },
        filters: {
          all: [
            ...(state.filters.role ? [{ role: state.filters.role }] : []),
            { hasactivesession: ['true'] },
            { ismessagesunread: ['true'] },
            { iseow: ['true'] },
          ],
          none: [
            { billingdata__status: ['cancelled'] },
            { hasactivesession: ['false'] },
            { isonboarding: ['true'] },
            { lastmessage_isengagementmessage: ['true'] },
          ],
          any: [
            ...state.filters.teams.map((t) => ({ coachassignment__team: t })),
            ...(state.filters.isnewuser ? [{ isnewuser: state.filters.isnewuser }] : []),
            ...(state.filters.isbgsnewuser ? [{ isbgsnewuser: state.filters.isbgsnewuser }] : []),
          ],
        },
      },
      {
        query: state.query,
        page: { size: state.size, current: state.current },
        search_fields: {
          firstname: { weight: 10 },
          lastname: { weight: 8 },
          email: { weight: 7 },
        },
        sort: [
          { ismessagesunread: 'desc' },
          { hasactivesession: 'desc' },
          { lastmessage__answerrequired: 'asc' },
          { lastmessage__createdat: 'desc' },
          { lastmessage__iscustomermsg: 'desc' },
        ],
        facets: {
          coachassignment__team: [{ type: 'value', sort: { count: 'asc' } }],
        },
        filters: {
          all: [
            ...(state.filters.role ? [{ role: state.filters.role }] : []),
            { hasactivesession: ['true'] },
            { ismessagesunread: ['true'] },
          ],
          none: [
            { billingdata__status: ['cancelled'] },
            { hasactivesession: ['false'] },
            {
              any: [{ isonboarding: ['true'] }, { iseow: ['true'] }],
            },
            { lastmessage_isengagementmessage: ['true'] },
          ],
          any: [
            ...state.filters.teams.map((t) => ({ coachassignment__team: t })),
            ...(state.filters.isnewuser ? [{ isnewuser: state.filters.isnewuser }] : []),
            ...(state.filters.isbgsnewuser ? [{ isbgsnewuser: state.filters.isbgsnewuser }] : []),
          ],
        },
      },
      {
        query: state.query,
        page: { size: state.size, current: state.current },
        search_fields: {
          firstname: { weight: 10 },
          lastname: { weight: 8 },
          email: { weight: 7 },
        },
        sort: [
          { ismessagesunread: 'desc' },
          { lastmessage__answerrequired: 'asc' },
          { lastmessage__createdat: 'desc' },
          { lastmessage__iscustomermsg: 'desc' },
        ],
        facets: {
          coachassignment__team: [{ type: 'value', sort: { count: 'asc' } }],
        },
        filters: {
          all: [
            ...(state.filters.role ? [{ role: state.filters.role }] : []),
            { ismessagesunread: ['true'] },
            { lastmessage_isengagementmessage: ['true'] },
          ],
          none: [{ billingdata__status: ['cancelled'] }],
          any: [
            ...state.filters.teams.map((t) => ({ coachassignment__team: t })),
            ...(state.filters.isnewuser ? [{ isnewuser: state.filters.isnewuser }] : []),
            ...(state.filters.isbgsnewuser ? [{ isbgsnewuser: state.filters.isbgsnewuser }] : []),
          ],
        },
      },
      {
        query: state.query,
        page: { size: state.size, current: state.current },
        search_fields: {
          firstname: { weight: 10 },
          lastname: { weight: 8 },
          email: { weight: 7 },
        },
        sort: [
          { ismessagesunread: 'desc' },
          { lastmessage__answerrequired: 'asc' },
          { lastmessage__createdat: 'desc' },
          { lastmessage__iscustomermsg: 'desc' },
        ],
        facets: {
          coachassignment__team: [{ type: 'value', sort: { count: 'asc' } }],
        },
        filters: {
          all: [
            ...(state.filters.role ? [{ role: state.filters.role }] : []),
            { hasactivesession: ['false'] },
            { ismessagesunread: ['true'] },
          ],
          none: [
            { billingdata__status: ['cancelled'] },
            { hasactivesession: ['true'] }, // covers both onboarding and eow
            { lastmessage_isengagementmessage: ['true'] },
          ],
          any: [
            ...state.filters.teams.map((t) => ({ coachassignment__team: t })),
            ...(state.filters.isnewuser ? [{ isnewuser: state.filters.isnewuser }] : []),
            ...(state.filters.isbgsnewuser ? [{ isbgsnewuser: state.filters.isbgsnewuser }] : []),
          ],
        },
      },
      {
        query: state.query,
        page: { size: state.size, current: state.current },
        search_fields: {
          firstname: { weight: 10 },
          lastname: { weight: 8 },
          email: { weight: 7 },
        },
        sort: [
          { ismessagesunread: 'desc' },
          { lastmessage__answerrequired: 'asc' },
          { lastmessage__createdat: 'desc' },
          { lastmessage__iscustomermsg: 'desc' },
        ],
        facets: {
          coachassignment__team: [{ type: 'value', sort: { count: 'asc' } }],
        },
        filters: {
          all: [
            ...(state.filters.role ? [{ role: state.filters.role }] : []),
            { lastmessage_isengagementmessage: ['true'] },
          ],
          none: [{ billingdata__status: ['cancelled'] }, { ismessagesunread: ['true'] }],
          any: [
            ...state.filters.teams.map((t) => ({ coachassignment__team: t })),
            ...(state.filters.isnewuser ? [{ isnewuser: state.filters.isnewuser }] : []),
            ...(state.filters.isbgsnewuser ? [{ isbgsnewuser: state.filters.isbgsnewuser }] : []),
          ],
        },
      },
      {
        query: state.query,
        page: { size: state.size, current: state.current },
        search_fields: {
          firstname: { weight: 10 },
          lastname: { weight: 8 },
          email: { weight: 7 },
        },
        sort: [
          { lastmessage__answerrequired: 'asc' },
          { lastmessage__createdat: 'desc' },
          { lastmessage__iscustomermsg: 'desc' },
        ],
        facets: {
          coachassignment__team: [{ type: 'value', sort: { count: 'asc' } }],
        },
        filters: {
          all: [...(state.filters.role ? [{ role: state.filters.role }] : [])],
          none: [
            { billingdata__status: 'cancelled' },
            { ismessagesunread: 'true' },
            { lastmessage_isengagementmessage: 'true' },
          ],
          any: [
            ...state.filters.teams.map((t) => ({ coachassignment__team: t })),
            ...(state.filters.isnewuser ? [{ isnewuser: state.filters.isnewuser }] : []),
            ...(state.filters.isbgsnewuser ? [{ isbgsnewuser: state.filters.isbgsnewuser }] : []),
          ],
        },
      },
    ],
  };
};
